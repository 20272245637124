@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'NouvelR';
    src: url('/public/fonts/NouvelR-Regular.woff2') format('woff'),
    url('/public/fonts/NouvelR-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'NouvelR-Bold';
    src: url('/public/fonts/NouvelR-Bold.woff2') format('woff'),
    url('/public/fonts/NouvelR-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

ul.dot li {
    padding-left: 20px
}

ul.dot li:before {
    position: relative;
    top: -4px;
    left: -10px;
    display: inline-block;
    width: 2px;
    height: 2px;
    background-color: black;
    content: '';
}

main {
  min-height: calc(100vh - 208px);
}

.float-label-input:focus-within label,
.float-label-input input:not(:placeholder-shown) + label,
.float-label-input.isValue label {
    transform: translateY(-1.5rem);
    font-size: 12px;
}

.terms a {
    text-decoration: underline;
}

.placeholder-small::placeholder {
    font-size:  12px;
    line-height: 14px;
    color: #978b7f;
}

.react-datepicker-popper {
    z-index: 11 !important;
}

.rc-scrollbars-track {
    background-color: #9ca3af;
}

.rc-scrollbars-thumb-h, .rc-scrollbars-thumb-v {
    background-color: #efdf00 !important;
}

.rc-scrollbars-track-h {
    bottom: 0 !important;
}

.rc-scrollbars-track-v {
    right: 0 !important;
}

.underline {
    text-decoration: underline;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}